<template>
  <div>
    <b-card>
      <ValidationObserver ref="role" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(updatePermission)">
          <form-btns class="mb-3" :label="$t('global.update')" :loading="loading" />
          <b-row>
            <b-col sm="6" md="4" v-for="(item, index) in permissions" :key="index">
              <PermissionCard :permission="item" />
            </b-col>
          </b-row>

          <form-btns :label="$t('global.update')" :loading="loading" />
        </b-form>
      </ValidationObserver>
    </b-card>
  </div>
</template>
<script>
import { core } from '../../../../config/pluginInit'
import PermissionCard from '../components/PermissionCard.vue'
import rolesServices from '../services/roles'
import moduleItemMixin from '../../../../Mixins/moduleItemMixin'

export default {
  mixins: [moduleItemMixin],
  components: { PermissionCard },
  data () {
    return {
      permissions: []
    }
  },
  methods: {
    async updatePermission () {
      if (!this.loading) {
        this.loading = true
        rolesServices.updatePermission(this.id, this.permissions).then(response => {
          this.loading = false
          core.showSnackbar('success', response.data.message)
          this.lastRoute.name ? this.$router.push(this.lastRoute) : this.$router.push({ name: 'administration.roles' })
        }).catch(() => {
          this.loading = false
        })
      }
    }
  },
  mounted () {
    core.index()
  },
  created () {
    if (this.id) {
      rolesServices.findOne(this.id).then(response => {
        this.permissions = response.data.data.rolePermissions
        this.loading = false
      })
    } else {
      this.loading = false
    }
  }
}
</script>
