<template>
  <div class="permission-card">
    <b-form-checkbox
      v-model="selectAll"
      :id="`${permission.id}-${permission.module_name}-selectAll`"
      :name="`${permission.id}-${permission.module_name}-selectAll`"
      @change="changeAllStatus"
    >
      {{ permission.module_name }}
    </b-form-checkbox>
    <div class="custom-divider"></div>
    <b-form-checkbox
      v-model="permission.view_any"
      :id="`${permission.id}-${permission.module_name}-view_any`"
      :name="`${permission.id}-${permission.module_name}-view_any`"
    >
      {{ $t('permissions.view_any') }}
    </b-form-checkbox>
    <b-form-checkbox
      v-model="permission.view"
      :id="`${permission.id}-${permission.module_name}-view`"
      :name="`${permission.id}-${permission.module_name}-view`"
    >
      {{ $t('permissions.view') }}
    </b-form-checkbox>

    <b-form-checkbox
      v-model="permission.create"
      :id="`${permission.id}-${permission.module_name}-create`"
      :name="`${permission.id}-${permission.module_name}-create`"
    >
      {{ $t('permissions.create') }}
    </b-form-checkbox>
    <b-form-checkbox
      v-model="permission.edit"
      :id="`${permission.id}-${permission.module_name}-edit`"
      :name="`${permission.id}-${permission.module_name}-edit`"
    >
      {{ $t('permissions.edit') }}
    </b-form-checkbox>

    <b-form-checkbox
      v-model="permission.delete"
      :id="`${permission.id}-${permission.module_name}-delete`"
      :name="`${permission.id}-${permission.module_name}-delete`"
    >
      {{ $t('permissions.delete') }}
    </b-form-checkbox>
    <b-form-checkbox
      v-model="permission.restore"
      :id="`${permission.id}-${permission.module_name}-restore`"
      :name="`${permission.id}-${permission.module_name}-restore`"
    >
      {{ $t('permissions.restore') }}
    </b-form-checkbox>
    <b-form-checkbox
      v-model="permission.force_delete"
      :id="`${permission.id}-${permission.module_name}-force_delete`"
      :name="`${permission.id}-${permission.module_name}-force_delete`"
    >
      {{ $t('permissions.force_delete') }}
    </b-form-checkbox>
  </div>
</template>
<script>
export default {
  props: {
    permission: { type: Object }
  },
  data () {
    return {
      selectAll: false
    }
  },
  methods: {
    changeAllStatus (status) {
      this.permission.view_any = status
      this.permission.view = status
      this.permission.create = status
      this.permission.edit = status
      this.permission.delete = status
      this.permission.force_delete = status
      this.permission.restore = status
    },
    checkSelectAll () {
      if (
        this.permission.view_any &&
        this.permission.view &&
        this.permission.create &&
        this.permission.edit &&
        this.permission.delete &&
        this.permission.force_delete &&
        this.permission.restore
      ) {
        this.selectAll = true
      } else {
        this.selectAll = false
      }
    }
  },
  watch: {
    // selectAll: function (value) {
    //   if (value) {
    //     this.changeAllStatus(true)
    //   } else {
    //     this.changeAllStatus(false)
    //   }
    // },
    'permission.view_any': function () { this.checkSelectAll() },
    'permission.view': function () { this.checkSelectAll() },
    'permission.create': function () { this.checkSelectAll() },
    'permission.edit': function () { this.checkSelectAll() },
    'permission.delete': function () { this.checkSelectAll() },
    'permission.force_delete': function () { this.checkSelectAll() },
    'permission.restore': function () { this.checkSelectAll() }
  }
}
</script>

<style lang="scss">
.permission-card {
  margin-bottom: 1.5rem;
  padding: 1rem;
  border: 1px solid #c9c9c9;
  box-shadow: 0 4px 16px 0 rgb(0 0 0 / 5%);
  border-radius: 0.5rem;
  .custom-divider {
    border-bottom: 1px solid #000;
    margin: 0.75rem 0;
  }
}
</style>
